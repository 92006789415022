import {
  BookingsQueryParams,
  WixOOISDKAdapter,
  CalendarSelections,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { Experiments } from '@wix/yoshi-flow-editor';

export const extractQueryParamValues = (
  wixSdkAdapter: WixOOISDKAdapter,
  queryParam: BookingsQueryParams,
): string[] => {
  const queryParamValues = wixSdkAdapter.getUrlQueryParamValue(queryParam);

  if (queryParamValues) {
    const queryParamValuesArray = queryParamValues.split(',');
    return queryParamValuesArray.map((value) => value.trim());
  }

  return [];
};

export const extractCalendarSelections = (
  wixSdkAdapter: WixOOISDKAdapter,
  experiments: Experiments,
): CalendarSelections | undefined => {
  if (!experiments.enabled('specs.bookings.multiServiceAppointment')) {
    return undefined;
  }

  const calendarOptionsQueryParam = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.SELECTIONS,
  );

  if (!calendarOptionsQueryParam) {
    return undefined;
  }

  return JSON.parse(decodeURIComponent(calendarOptionsQueryParam));
};
