import { Basket } from '@wix/bookings-services-preferences-modal';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import { CloseDialogAction } from '../closeDialog/closeDialog';
import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { navigateToBookingsCalendarPage } from '@wix/bookings-catalog-calendar-viewer-navigation';

export type OnServicesPreferencesConfirm = (basket: Basket) => void;

export const createOnServicesPreferencesConfirm =
  (
    {
      context,
      getControllerState,
    }: ActionFactoryParams<CalendarState, CalendarContext>,
    closeDialog: CloseDialogAction,
  ): OnServicesPreferencesConfirm =>
  (basket) => {
    const [state] = getControllerState();

    const { selectedTimezone } = state;
    const {
      wixSdkAdapter,
      experiments,
      flowAPI: {
        controllerConfig: { wixCodeApi },
      },
    } = context;

    const referral = wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.REFERRAL,
    );

    closeDialog();
    if (
      experiments.enabled(
        'specs.bookings.navigateToCalendarPageWithNavigationModule',
      )
    ) {
      navigateToBookingsCalendarPage(wixCodeApi, {
        serviceSlugOrBasket: basket,
        navigationContext: {
          timezone: selectedTimezone,
          referral,
        },
      });
    } else {
      wixSdkAdapter.navigateToBookingsCalendarPage(
        basket,
        { referral, timezone: selectedTimezone },
        experiments.enabled('specs.bookings.RouterPrefixNavigation'),
      );
    }
  };
