import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import {
  QueryAvailabilityResponse,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { Plan } from '@wix/ambassador-checkout-server/types';
import { ServiceOptionsAndVariants } from '@wix/ambassador-bookings-catalog-v1-service-options-and-variants/types';
import { Booking } from '@wix/bookings-checkout-api';
import { Service } from '@wix/bookings-uou-types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/api';
import { bookingsCalendarErrorMessages } from '@wix/bi-logger-wixboost-ugc/v2';
import { FlowElements } from './Hooks/useFlow';
import { EmptyStateType } from './ViewModel/emptyStateViewModel/emptyStateViewModel';
import { CalendarStatus } from './ViewModel/widgetViewModel/widgetViewModel';
import {
  DialogState,
  DialogType,
} from './ViewModel/dialogViewModel/dialogViewModel';
import {
  CalendarViewModel,
  createMemoizedCalendarViewModelFactory,
} from './ViewModel/viewModel';
import { CalendarActions, createCalendarActions } from './Actions/actions';
import { CalendarApi } from '../../api/CalendarApi';
import {
  createControlledComponent,
  Render,
} from '../../utils/ControlledComponent/ControlledComponent';
import { SelectedBookingPreference } from '../../utils/bookingPreferences/bookingPreferences';
import { createWixSdkAdapter } from '../../utils/sdkAdapterFactory';
import { createInitialState } from '../../utils/state/initialStateFactory';
import {
  CalendarContext,
  createCalendarContext,
} from '../../utils/context/contextFactory';

import {
  CalendarErrors,
  FilterOptions,
  Preset,
  SelectedVariantOptions,
  SlotsStatus,
  TriggeredByOptions,
} from '../../types/types';
import calendarSettingsParams from './settingsParams';
import { isCalendarPage, isCalendarWidget } from '../../utils/presets';
import { onLocationURLChange } from './controllerPrePageReady';
import { extractCalendarSelections } from '../../utils/queryParams/queryParams';
import type { ServicesPreferencesModalData } from '@wix/bookings-services-preferences-modal/types';

export type TFunction = (
  key: string | string[],
  options?: Record<string, any>,
  defaultValue?: string,
) => string;

export type CalendarState = {
  calendarStatus: CalendarStatus;
  slotsStatus: SlotsStatus;
  servicesInView: Service[];
  allCalendarBookableServices?: Service[];
  selectedDate?: string;
  selectedDateTrigger?: TriggeredByOptions;
  selectedTimezone?: string;
  selectedRange?: {
    from: string;
    to: string;
  };
  selectedTime?: string;
  selectableSlotsAtSelectedTime?: SlotAvailability[];
  availableSlots?: QueryAvailabilityResponse;
  availableSlotsPerDay?: QueryAvailabilityResponse;
  selectedBookingPreferences: SelectedBookingPreference[];
  calendarErrors: CalendarErrors[];
  rescheduleBookingDetails?: Booking;
  dialog?: {
    type: DialogType;
    state: DialogState;
  };
  filterOptions: FilterOptions;
  focusedElement?: FlowElements;
  initialErrors: EmptyStateType[];
  purchasedPricingPlans: Plan[];
  isUserLoggedIn: boolean;
  selectedVariantsOptions: SelectedVariantOptions[];
  serviceVariantsMap: Record<string, ServiceOptionsAndVariants>;
  servicesPreferencesModalData?: ServicesPreferencesModalData;
};

export const createControllerFactory = (
  preset: Preset,
  settingsParams: any,
) => {
  const createController: CreateControllerFn = async ({
    flowAPI,
  }: ControllerParams) => {
    const { controllerConfig, experiments } = flowAPI;
    const isCalendarPagePreset = isCalendarPage(preset);
    const isCalendarWidgetPreset = isCalendarWidget(preset);
    const wixSdkAdapter = createWixSdkAdapter(controllerConfig, experiments);
    let rerender: Render<CalendarState> = async () => {};
    let publicData = controllerConfig.config.publicData.COMPONENT || {};
    let calendarContext: CalendarContext;
    let initialState: CalendarState;
    let servicesInView: Service[];

    const pageReady = async () => {
      const { reportError } = flowAPI;

      const calendarApi = new CalendarApi({
        wixSdkAdapter,
        reportError,
        flowAPI,
        settingsParams,
        preset,
      });

      const initialErrors: EmptyStateType[] = [];

      const onError = (type: EmptyStateType) => initialErrors.push(type);

      const isAnonymousCancellationFlow =
        wixSdkAdapter.getUrlQueryParamValue(BookingsQueryParams.REFERRAL) ===
        'batel';
      const selectedService = flowAPI.settings.get(
        settingsParams.selectedService,
      );
      const shouldUseCalendarDummyViewModel =
        (experiments.enabled('specs.bookings.useFlowApiEnvironmentOverSDK')
          ? flowAPI.environment.isEditor
          : wixSdkAdapter.isEditorMode()) && selectedService === '';
      const currentUser = controllerConfig.wixCodeApi.user.currentUser;
      const calendarSelections = extractCalendarSelections(
        wixSdkAdapter,
        flowAPI.experiments,
      );
      const requestedServicesIds = calendarSelections?.services?.map(
        (service) => service.id,
      );
      const [
        catalogData,
        rescheduleBookingDetails,
        isPricingPlanInstalled,
        isMemberAreaInstalled,
      ] = await Promise.all([
        calendarApi.getCatalogData({
          onError,
          serviceIds: requestedServicesIds,
        }),
        calendarApi.getBookingDetails({
          onError: (type: EmptyStateType) => {
            if (!isAnonymousCancellationFlow) {
              onError(type);
            }
          },
        }),
        wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
        wixSdkAdapter.isMemberAreaInstalled().catch(() => false),
      ]);

      servicesInView = catalogData?.services || [];

      const allPurchasedPricingPlans =
        (isCalendarPagePreset || isCalendarWidgetPreset) &&
        (!calendarSelections || calendarSelections.services.length === 1)
          ? await calendarApi.getPurchasedPricingPlans({
              currentUser,
              service: servicesInView.length ? servicesInView[0] : undefined,
            })
          : [];
      initialState = createInitialState({
        servicesInView,
        allCalendarBookableServices: catalogData?.allCalendarBookableServices,
        staffMembers: catalogData?.staffMembers,
        wixSdkAdapter,
        rescheduleBookingDetails,
        initialErrors,
        allPurchasedPricingPlans,
        isPricingPlanInstalled,
        isUserLoggedIn: currentUser.loggedIn,
        servicesVariants: catalogData?.servicesVariants,
        preset,
        experiments,
        calendarSelections,
      });
      calendarContext = await createCalendarContext({
        flowAPI,
        businessInfo: catalogData?.businessInfo,
        activeFeatures: catalogData?.activeFeatures,
        calendarApi,
        wixSdkAdapter,
        initialState,
        isPricingPlanInstalled,
        isMemberAreaInstalled,
        settingsParams,
        preset,
        calendarSelections,
      });

      const { onStateChange, render, controllerActions, setState } =
        await createControlledComponent<
          CalendarState,
          CalendarActions,
          CalendarViewModel,
          CalendarContext
        >({
          controllerConfig,
          initialState,
          viewModelFactory: createMemoizedCalendarViewModelFactory(
            shouldUseCalendarDummyViewModel,
          ),
          actionsFactory: createCalendarActions,
          context: calendarContext,
        });
      rerender = render;
      if (
        isAnonymousCancellationFlow &&
        !controllerConfig.wixCodeApi.user.currentUser.loggedIn &&
        !(experiments.enabled('specs.bookings.useFlowApiEnvironmentOverSDK')
          ? flowAPI.environment.isSSR
          : wixSdkAdapter.isSSR()) &&
        wixSdkAdapter.getUrlQueryParamValue(BookingsQueryParams.BOOKING_ID)
      ) {
        setTimeout(async () => {
          try {
            // @ts-expect-error
            await controllerConfig.wixCodeApi.user.promptLogin();
          } catch (e) {
            //
          }

          const updatedRescheduleBookingDetails =
            await calendarApi.getBookingDetails({
              onError,
            });
          setState({
            rescheduleBookingDetails: updatedRescheduleBookingDetails,
          });
        }, 10);
      }

      if (isCalendarPagePreset) {
        const seoData = catalogData?.seoData?.length
          ? catalogData.seoData[0]
          : undefined;
        if (experiments.enabled('specs.bookings.useOneLinerInsteadOfWixSDK')) {
          await flowAPI.controllerConfig.wixCodeApi.seo.renderSEOTags({
            itemType: ITEM_TYPES.BOOKINGS_CALENDAR,
            itemData: seoData as any,
          });
        } else {
          await wixSdkAdapter.renderSeoTags(
            ITEM_TYPES.BOOKINGS_CALENDAR,
            seoData,
          );
        }
      }

      const { biLogger } = calendarContext;

      if (
        !(experiments.enabled('specs.bookings.useFlowApiEnvironmentOverSDK')
          ? flowAPI.environment.isSSR
          : wixSdkAdapter.isSSR())
      ) {
        onStateChange((state) => {
          biLogger.update(state, calendarContext);
        });
      }

      if (isCalendarPagePreset || isCalendarWidgetPreset) {
        if (experiments.enabled('specs.bookings.useOneLinerInsteadOfWixSDK')) {
          flowAPI.controllerConfig.wixCodeApi.user.onLogin(
            controllerActions.onUserLoggedIn,
          );
        } else {
          wixSdkAdapter.onUserLogin(controllerActions.onUserLoggedIn);
        }
      }

      const serviceNotFound = initialState.initialErrors.includes(
        EmptyStateType.SERVICE_NOT_FOUND,
      );

      if (serviceNotFound) {
        void biLogger.report(
          bookingsCalendarErrorMessages({
            errorMessage: 'SERVICE_NOT_FOUND',
          }),
        );
      }
    };

    const isRunPageReadyOnCalendarPageEnabled = flowAPI.experiments.enabled(
      'specs.bookings.calendar.runPageReadyOnCalendarPage',
    );

    if (!isRunPageReadyOnCalendarPageEnabled || isCalendarPagePreset) {
      onLocationURLChange({
        callback: pageReady,
        wixCodeApi: controllerConfig.wixCodeApi,
        wixSdkAdapter,
        preset,
        isRunPageReadyOnCalendarPageEnabled,
      });
    }

    return {
      pageReady,
      exports() {
        const wrapOverrideCallback = (
          sdkMethod: keyof WixOOISDKAdapter,
          callback: (data: any) => Promise<any>,
        ) => {
          const originalSdkMethod: Function =
            wixSdkAdapter[sdkMethod].bind(wixSdkAdapter);
          (wixSdkAdapter[sdkMethod] as any) = function (data: any) {
            const originalArguments = arguments;
            return callback(data).then(async ({ shouldNavigate = true } = {}) =>
              shouldNavigate
                ? originalSdkMethod.apply(
                    originalSdkMethod,
                    originalArguments as any,
                  )
                : null,
            );
          };
        };
        return {
          onNextClicked(overrideCallback: Function) {
            wrapOverrideCallback(
              'navigateToBookingsFormPage',
              ({ slotAvailability }) =>
                overrideCallback({
                  service: servicesInView[0],
                  slotAvailability,
                }),
            );
            wrapOverrideCallback('navigateToMembersArea', () =>
              overrideCallback({
                service: servicesInView[0],
              }),
            );
          },
        };
      },
      async updateConfig($w, newConfig) {
        const updatedPublicData = newConfig.publicData.COMPONENT || {};
        const isCalendarLayoutChanged =
          publicData.calendarLayout !== updatedPublicData.calendarLayout;
        const isLocationsSelectionChanged =
          publicData.selectedLocations !== updatedPublicData.selectedLocations;
        const isCategoriesSelectionChanged =
          publicData.selectedCategories !==
          updatedPublicData.selectedCategories;
        const isServiceSelectedChanged =
          publicData.selectedService !== updatedPublicData.selectedService;
        const isSlotsAvailabilityChanged =
          publicData.slotsAvailability !== updatedPublicData.slotsAvailability;
        publicData = updatedPublicData;

        if (
          !isCalendarPagePreset &&
          (isLocationsSelectionChanged ||
            isCategoriesSelectionChanged ||
            isServiceSelectedChanged ||
            isSlotsAvailabilityChanged)
        ) {
          pageReady();
        } else {
          await rerender({
            resetState: isCalendarLayoutChanged,
          });
        }
      },
    };
  };

  return createController;
};

export default createControllerFactory(
  Preset.CALENDAR_PAGE,
  calendarSettingsParams,
);
